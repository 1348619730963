<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Add New Plan
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Admins</a></li>
          <li class="breadcrumb-item active" aria-current="page">New</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
    <div class="row">
      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <ValidationProvider name="planNo" rules="required">
              <b-form-group slot-scope="{ errors }" label="Plan No"  label-for="planNo">
                <b-form-input type="text" id="planNo" v-model="planNo" placeholder="Plan No"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="district" rules="required">
              <b-form-group slot-scope="{ errors }" label="District"  label-for="district">
                <b-form-input type="text" id="district" v-model="district" placeholder="District"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="city" rules="required">
              <b-form-group slot-scope="{ errors }" label-for="city" horizontal label="City">
                <b-form-select id="city" v-model="city_id" :options="cities"/>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
          <h4 class="card-title">Points</h4>
          <p class="card-description">
            Add longitude & latitude of points and click the add button to add until it's 3.
          </p>
          <ValidationProvider name="longitude">
           <b-form-group label="Longitude" label-for="longitude">
             <b-form-input type="text" :disabled="points.length > 2" id="longitude" v-model="longitude" placeholder="Longitude"></b-form-input>
           </b-form-group>
           </ValidationProvider>
           <ValidationProvider name="latitude">
            <b-form-group label="Latitude" label-for="longitude">
              <b-form-input type="text" :disabled="points.length > 2" id="latitude" v-model="latitude" placeholder="Latitude"></b-form-input>
            </b-form-group>
            </ValidationProvider>
            <b-form-group class="float-right">
              <b-button type="button" :disabled="!addCheck" @click="addPoint" variant="success">Add Point</b-button>
            </b-form-group>
            <b-table striped hover :items="points">
            <template v-slot:cell(action)="data">
              <b-button variant="danger" @click="removePoint(data.value.action)">remove</b-button>
            </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-form-group class="float-right">
              <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
              <b-button type="button" @click="resetForm" variant="light">Reset</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'planAddNew',
  data () {
    return {
      neighborhood: "",
      planNo: "",
      district: "",
      block: "",
      meter_sq: "",
      longitude: "",
      latitude: "",
      city_id: "",
      create_text: "Add Plan",
      points: []
    }
  },
  components: {
    ValidationObserver
  },
  computed: {
    addCheck() {
      return this.longitude != "" && this.latitude != "" && (this.points.length < 3)
    },
    cities() {
      return this.$store.getters['city/listCities']
    }
  },
  methods: {
    onSubmit() {
      this.create_text = "Creating..."
      let payload = {
        planNo: this.planNo,
        city_id: this.city_id,
        district: this.district,
        points: this.points
      }
      this.$store.dispatch('plan/createPlan', payload)
      .then(() => {
        this.create_text = "Create Plan"
        this.resetForm()
        this.$snotify.success("Plan created successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.create_text = "Create Admin"

        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    addPoint() {
      this.points.push({lat: this.latitude, lng: this.longitude, action:this.points.length})
      this.latitude = this.longitude = ""
    },
    removePoint(index) {
      this.points.splice(index, 1)
    },
    resetForm() {
      this.points = []
      this.plot = this.planNo = this.district = this.city_id = ""
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    }
  },
  created() {
    const payload = {
      meta: {
        page: 1,
        perPage: 200
     },
   }
   this.$store.dispatch("city/fetchCities", payload)
  }
}
</script>
